import { Button, Link as ExternalLink, Text, useToast } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_ROUTE, OFFER_LOCATION, OFFER_TYPE } from '~/lib/constants';
import { goUrl, neutralizeEvent } from '~/lib/helpers';
import { IS_HTTP_URL_RE } from '~/lib/regexp';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';
import { GoingToBrandUrlPopup } from '../going-to-brand-url-popup';

const MERCHANT_NAV_DELAY = 1750;

export function BrandOfferCtaCardLinked({ offer, brand }) {
  const navigate = useNavigate();
  const toast = useToast();
  const { brandsTranslation } = useTranslations();
  const [showGoingToBrandUrlPopup, setShowGoingToBrandUrlPopup] = useState();
  const hasCommissionGroups = offer.commission_groups?.length > 0;

  const handleClick = useCallback(
    (event) => {
      neutralizeEvent(event);

      // render popup
      setShowGoingToBrandUrlPopup(true);

      const hasProtocol = brand?.website && IS_HTTP_URL_RE.test(brand?.website) === true;
      let url;

      try {
        url = new URL(hasProtocol ? brand?.website : `https://${brand?.website}`);
      } catch (_) {
        url = undefined;
      }

      // redirect to offer website after timeout
      let timerId = setTimeout(() => {
        if (!url) {
          navigate(BRANDS_ROUTE);

          toast({
            title: 'Valid advertiser website not found',
            variant: TOAST_VARIANT.ERROR,
            status: TOAST_VARIANT.ERROR,
          });
        }

        goUrl(url.toString(), true);
      }, MERCHANT_NAV_DELAY);

      return () => {
        if (timerId) {
          clearTimeout(timerId);
        }
      };
    },
    [brand, navigate, toast]
  );

  const noLinkToOfferWebsite = offer?.location === OFFER_LOCATION.IN_STORE_ONLY || !brand?.website;

  if (noLinkToOfferWebsite) {
    return (
      <Text textStyle='minor' mt={6} fontSize='medium'>
        {brandsTranslation.offerType[OFFER_TYPE.CARD_LINKED].brandOfferCtaAuthenticated.inStoreOnly}
      </Text>
    );
  }

  return (
    <React.Fragment>
      <ExternalLink href={brand?.website ?? ''} isExternal>
        <Button variant='baseStyle' px={[6, 6, 12]} onClick={handleClick}>
          {brandsTranslation.offerType[OFFER_TYPE.CARD_LINKED].brandOfferCtaAuthenticated.online}
        </Button>
      </ExternalLink>

      <GoingToBrandUrlPopup
        brand={brand}
        hasCommissionGroups={hasCommissionGroups}
        isOpen={showGoingToBrandUrlPopup}
        offer={offer}
        onClose={() => {
          setShowGoingToBrandUrlPopup(false);
        }}
      />
    </React.Fragment>
  );
}

BrandOfferCtaCardLinked.propTypes = {
  brand: PropTypes.object,
  offer: PropTypes.object,
};
