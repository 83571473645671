import { useCallback } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { CARDS_API_URL } from '~/lib/constants';

export const useInstitutionsApi = () => {
  const fetchUrl = useFetchUrl();

  const fetchCards = useCallback(async () => {
    try {
      const response = await fetchUrl(`${CARDS_API_URL}linked-cards`);

      return await response.json();
    } catch (error) {
      console.error('Error fetching cards:', error);
      throw error;
    }
  }, [fetchUrl]);

  const fetchLinkToken = useCallback(async () => {
    try {
      const response = await fetchUrl(`${CARDS_API_URL}link-token`);

      return await response.json();
    } catch (error) {
      console.error('Error fetching link token:', error);
      throw error;
    }
  }, [fetchUrl]);

  const setToken = useCallback(
    async (publicToken, institution) => {
      try {
        await fetchUrl(`${CARDS_API_URL}public-token`, {
          method: 'POST',
          body: JSON.stringify({
            public_token: publicToken,
            institution_id: institution.institution_id,
            institution_name: institution.name,
          }),
        });
      } catch (error) {
        console.error('Error setting token:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  const confirmCardLastFour = useCallback(
    async (cards) => {
      const body = { cards };
      try {
        return await fetchUrl(CARDS_API_URL, {
          method: 'PATCH',
          body: JSON.stringify(body),
        });
      } catch (error) {
        console.error('Error confirming last four digits:', error);
        throw error;
      }
    },
    [fetchUrl]
  );

  return { fetchCards, fetchLinkToken, setToken, confirmCardLastFour };
};
