import { Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import { BRANDS_NOT_FOUND_ROUTE } from '~/lib/constants';
import { BrandsList } from './brands-list';

export const BrandNotFoundPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Make sure pathname is correct, user might be directed here from a brand wildcard route
  useEffect(() => {
    if (location.pathname !== BRANDS_NOT_FOUND_ROUTE) {
      navigate(BRANDS_NOT_FOUND_ROUTE, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <PageContainer>
      <HStack spacing={10} mb={20}>
        <Image src='/img/cactus-not-found.svg' height={250} width={250} />
        <VStack spacing={8} alignItems='flex-start' color='secondary.primary'>
          <Heading as='h1' fontSize='xl'>
            Brand Cannot Be Found
          </Heading>
          <Text fontSize='1.125rem'>
            Explore other brands below.
            <br /> Not what you’re looking for? Try a different search!
          </Text>
        </VStack>
      </HStack>
      <BrandsList />
    </PageContainer>
  );
};
