import { useCallback } from 'react';
import { DEFAULT_HEADERS, BASE_URL } from '~/lib/constants';
import { NO_HEADERS } from '~/lib/constants';
import { BadStatusError } from '~/lib/errors';
import { responseStatusIsGood } from '~/lib/helpers';
import { useAccountAction } from './account/useAccountAction';

/**
 * fetchUrl wraps the native 'fetch' and does two things:
 *
 * 1. It uses the `getValidToken` method to obtain (and possibly refresh) a valid auth token.
 * 2. It uses the `getHeaders` method to inject headers into the request including the auth token
 *    and some sensible defaults. All headers customizable and overridable.
 *
 * There are two levels of cancellation you can use to reduce the auto-headers.
 *
 * 1. passing {omitAccessToken: true} in customHeaderConfig will remove the access token (but leave in the publisher ID)
 * 2. passing NO_HEADERS obviates the entire headersAsync() call. Do this for any third party APIs or static
 * resources for faster execution.
 *
 * You can, for instance, put headers in the config AND pass NO_HEADERS and your config headers will be used.
 *
 */

export function getHeaders(customHeaderConfig, accessToken) {
  const result = {
    ...DEFAULT_HEADERS,
    ...(customHeaderConfig?.headers ?? {}),
  };

  if (accessToken && !customHeaderConfig?.omitAccessToken) {
    result.Authorization = `Bearer ${accessToken}`;
  }

  return result;
}

export const useFetchUrl = () => {
  const { getValidToken } = useAccountAction();

  const fetchUrl = useCallback(
    async (url, fetchConfig = {}, customHeaderConfig = {}) => {
      if (customHeaderConfig === NO_HEADERS) {
        return fetch(`${BASE_URL}${url}`, fetchConfig);
      }

      const freshestToken = await getValidToken();

      const headers = getHeaders(customHeaderConfig, freshestToken);

      const fetchOptions = { ...fetchConfig, headers };

      const response = await fetch(`${BASE_URL}${url}`, fetchOptions);

      if (!responseStatusIsGood(response)) {
        throw new BadStatusError(response);
      }

      return response;
    },
    [getValidToken]
  );

  return fetchUrl;
};
