import { PageContainer } from '~/components/page-container';
import { useTranslations } from '~/hooks/useTranslations';
import { BrandsList } from '~/pages/brands/brands-list';
import CategorySlider from './category-slider';
import { StoresWeLoveSection } from './stores-we-love-section';

export default function BrandsPage() {
  const { brandsTranslation } = useTranslations();

  return (
    <PageContainer id='brands-page-container'>
      <CategorySlider carouselCategories={brandsTranslation.CAROUSEL_IMAGES} />
      <StoresWeLoveSection />
      <BrandsList />
    </PageContainer>
  );
}
