import { useConfig } from '~/hooks/useConfig';

const DEFAULT_PUBLISHER_NAME = 'Cashback Rewards';

const usePublisherName = () => {
  const {
    publisher: { publisher_name },
  } = useConfig();

  return { publisherName: publisher_name || DEFAULT_PUBLISHER_NAME };
};

export default usePublisherName;
