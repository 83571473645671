import * as PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useConfig } from '~/hooks/useConfig.js';
import { useFeatureFlag } from '~/hooks/useFeatureFlag';
import { FLAGS } from '~/lib/constants';
import { sha256Hash } from '~/lib/sha256Hash.js';

const configId = 'G-54VQHMR3SF';
const scriptEndpoint = `https://www.googletagmanager.com/gtag/js?id=${configId}`;

const GoogleAnalyticsContext = createContext({ gtag: null, logEvent: null });

export const GoogleAnalyticsProvider = ({ children }) => {
  const googleAnalyticsIsLive = useFeatureFlag(FLAGS.GOOGLE_ANALYTICS_IS_LIVE);
  const publisherConfig = useConfig();
  const { userEmail, userId } = useAccountState();
  const [hashedEmail, setHashedEmail] = useState(null);
  const [gtag, setGtag] = useState(null);
  const [, forceRender] = useState(0);

  const isInitialized = useRef(false);

  const logEvent = (eventName, eventParams) => {
    if (gtag) {
      gtag('event', eventName, eventParams);
    }
  };

  useEffect(() => {
    if (userEmail) {
      sha256Hash(userEmail)
        .then((hash) => setHashedEmail(hash))
        .catch(() => setHashedEmail(null));
    } else {
      setHashedEmail(null);
    }
  }, [userEmail]);

  useEffect(() => {
    if (!googleAnalyticsIsLive || isInitialized.current) return;

    const script = document.createElement('script');
    script.id = 'googleTagManagerScript';
    script.src = scriptEndpoint;

    script.onload = () => {
      const commonData = {
        email: hashedEmail ?? 'logged_out',
        userId: userId,
        env: publisherConfig?.env,
        product: 'rewards',
        publisher_id: publisherConfig?.publisher?.publisher_id,
      };

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', configId, commonData);

      window.gtag = gtag;

      setGtag(() => gtag);

      isInitialized.current = true;
    };

    document.body.appendChild(script);

    return () => {
      document.getElementById('googleTagManagerScript')?.remove();
    };
  }, [
    googleAnalyticsIsLive,
    hashedEmail,
    publisherConfig?.env,
    publisherConfig?.publisher?.publisher_id,
    userId,
  ]);

  useEffect(() => {
    if (isInitialized.current && window.gtag) {
      const commonData = {
        email: hashedEmail ?? 'logged_out',
        userId: userId,
        env: publisherConfig?.env,
        product: 'rewards',
        publisher_id: publisherConfig?.publisher?.publisher_id,
        update: true, // special flag to update the config
      };
      window.gtag('config', configId, commonData);
      forceRender((prev) => prev + 1);
    }
  }, [hashedEmail, publisherConfig?.env, publisherConfig?.publisher?.publisher_id, userId]);

  return (
    <GoogleAnalyticsContext.Provider value={{ gtag: gtag, logEvent: gtag ? logEvent : null }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export const useGoogleAnalytics = () => {
  const context = useContext(GoogleAnalyticsContext);
  if (!context) {
    throw new Error('useGoogleAnalytics must be used within a GoogleAnalyticsProvider');
  }
  return context;
};

GoogleAnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
