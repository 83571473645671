import * as PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useSearch } from '~/hooks/useSearch';

export const CategoriesContext = createContext({ categories: [] });

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);

  const { categories: categoriesFromSearch } = useSearch();

  useEffect(() => {
    setCategories(categoriesFromSearch);
  }, [categoriesFromSearch]);

  return <CategoriesContext.Provider value={{ categories }}>{children}</CategoriesContext.Provider>;
};

CategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
