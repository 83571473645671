import { Icon } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const DiningUtensilsIcon = ({ size = 4, color = 'currentColor' }) => {
  return (
    <Icon width='auto' height={size} viewBox='0 0 11 13' fill='none' color={color}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='11'
        height='13'
        viewBox='0 0 11 13'
        fill='none'
      >
        <path
          d='M9.75 0.5C10.1484 0.5 10.5 0.851562 10.5 1.25V6.125V8.75V11.75C10.5 12.1719 10.1484 12.5 9.75 12.5C9.32812 12.5 9 12.1719 9 11.75V8.75H8.25C7.40625 8.75 6.75 8.09375 6.75 7.25V4.625C6.75 1.25 9.375 0.5 9.75 0.5ZM1.5 0.875V4.03906C1.5 4.15625 1.59375 4.25 1.71094 4.25C1.85156 4.25 1.94531 4.17969 1.94531 4.03906L2.25 0.851562C2.25 0.664062 2.41406 0.5 2.625 0.5C2.8125 0.5 2.97656 0.664062 2.97656 0.851562L3.28125 4.03906C3.28125 4.17969 3.39844 4.25 3.51562 4.25C3.63281 4.25 3.75 4.15625 3.75 4.03906V0.875C3.75 0.6875 3.89062 0.523438 4.07812 0.523438C4.26562 0.5 4.42969 0.617188 4.47656 0.804688L5.17969 3.99219C5.22656 4.15625 5.25 4.29688 5.25 4.4375C5.25 5.51562 4.40625 6.40625 3.375 6.5V11.75C3.375 12.1719 3.02344 12.5 2.625 12.5C2.20312 12.5 1.875 12.1719 1.875 11.75V6.5C0.820312 6.40625 0 5.51562 0 4.4375C0 4.29688 0 4.15625 0.046875 3.99219L0.75 0.804688C0.796875 0.617188 0.960938 0.5 1.14844 0.523438C1.33594 0.523438 1.5 0.6875 1.5 0.875ZM2.625 4.4375H2.60156H2.625Z'
          fill={color}
        />
      </svg>
    </Icon>
  );
};

DiningUtensilsIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default DiningUtensilsIcon;
