import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import ContactSuccessForm from '~/components/footer/contact-success-form';
import { ProtectedRoutes } from '~/components/protected-routes';
import { AccountCardsPage, AccountPage, BrandPage, SearchPage, TransactionsPage } from '~/pages';
import BrandsPage from '~/pages/brands/brands-page';
import { ErrorPage } from '~/pages/error-page';
import LandingPage from '~/pages/landing/landing-page';
import PageLayout from '../components/page-layout';
import { BrandNotFoundPage } from '../pages/brands/brand-not-found-page';
import { Error404Page } from '../pages/error/error-404-page';
import { Provider } from './provider';

const ContactUs = React.lazy(() => import('~/components/footer/contact-us'));

const LazyContactUs = () => (
  <React.Suspense fallback={<Spinner />}>
    <ContactUs />
  </React.Suspense>
);

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <Provider>
        <PageLayout />
      </Provider>
    ),
    children: [
      { path: '', element: <LandingPage />, errorElement: <ErrorPage /> },
      {
        path: 'brands',
        element: <Outlet />,
        children: [
          { path: '', element: <BrandsPage />, errorElement: <ErrorPage /> },
          { path: 'not-found', element: <BrandNotFoundPage />, errorElement: <ErrorPage /> },
          {
            path: 'search',
            element: <SearchPage />,
          },
          {
            path: ':brandName/:brandUid',
            element: <BrandPage />,
            errorElement: <ErrorPage />,
          },
          { path: '*', element: <BrandNotFoundPage />, errorElement: <ErrorPage /> },
        ],
      },
      {
        path: 'contact',
        element: <LazyContactUs />,
      },
      {
        path: 'contact/success',
        element: <ContactSuccessForm />,
      },
      {
        path: 'search',
        element: <SearchPage />,
      },
      {
        path: 'reset-password',
        element: <BrandsPage />,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: 'account',
            element: <AccountPage />,
          },
          {
            path: 'account/cards',
            element: <AccountCardsPage />,
          },
          {
            path: 'account/transactions',
            element: <TransactionsPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Error404Page />,
      },
    ],
  },
]);

export const Router = () => <RouterProvider router={router} />;
