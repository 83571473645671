import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MODALS } from '~/lib/constants';
import { useModalAction } from './modal/useModalAction';

const RESET_PASSWORD_ROUTE = '/reset-password';

/**
 *
 * @description A hook which listens for the route users are sent to when they want to reset their password and
 * conditionally shows the change password modal.
 */
export const useResetPassword = () => {
  const { pathname } = useLocation();
  const { openModal } = useModalAction();

  useEffect(() => {
    if (pathname === RESET_PASSWORD_ROUTE) {
      openModal(MODALS.RESET_PASSWORD);
    }
  }, [pathname, openModal]);
};
