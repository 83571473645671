import { useNavigate } from 'react-router-dom';
import { useTranslations } from '~/hooks/useTranslations';
import { ErrorPage } from '../error-page';

export const Error404Page = () => {
  const { genericTranslation } = useTranslations();
  const navigate = useNavigate();

  return (
    <ErrorPage
      img='/img/404-not-found.svg'
      title={genericTranslation.errorPage[404].title}
      message={genericTranslation.errorPage[404].message}
      action={() => navigate('/')}
      actionText={genericTranslation.errorPage[404].actionText}
    />
  );
};
