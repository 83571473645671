import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useModalAction } from '~/hooks/modal/useModalAction';
import { useConfig } from '~/hooks/useConfig';
import { useTranslations } from '~/hooks/useTranslations';
import { ACCOUNT_ROUTE, MODALS } from '~/lib/constants';
import { goUrl, neutralizeEvent } from '~/lib/helpers';

export function HeaderAccountButtons({ setSettingsModalIsOpen }) {
  const { signOut } = useAccountAction();
  const { isLoggedIn, userEmail } = useAccountState();
  const {
    account: { sign_in_url, sign_up_url },
  } = useConfig();
  const { genericTranslation } = useTranslations();

  const { openModal } = useModalAction();

  const goToSignIn = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_in_url) {
        goUrl(sign_in_url);
      } else {
        openModal(MODALS.SIGN_IN);
      }
    },
    [openModal, sign_in_url]
  );

  const goToSignUp = useCallback(
    (e) => {
      neutralizeEvent(e);
      if (sign_up_url) {
        goUrl(sign_up_url);
      } else {
        openModal(MODALS.SIGN_UP);
      }
    },
    [openModal, sign_up_url]
  );

  // logged in, not mobile
  if (isLoggedIn) {
    return (
      <Menu>
        <MenuButton>
          <Text textStyle='header-menu-item'>{genericTranslation.userMenu.buttonText}</Text>
        </MenuButton>

        <MenuList>
          <MenuItem bg='none' onClick={neutralizeEvent} cursor='default'>
            <VStack align='start'>
              <Text color='secondary.primary' fontSize='sm' fontWeight='medium'>
                {genericTranslation.userMenu.account}
              </Text>
              <Text color='neutral.primary' fontWeight='medium'>
                {userEmail}
              </Text>
            </VStack>
          </MenuItem>
          <MenuDivider />
          <MenuGroup>
            <Link to={ACCOUNT_ROUTE}>
              <MenuItem>
                <Text color='neutral.primary' fontWeight='medium'>
                  {genericTranslation.userMenu.myWallet}
                </Text>
              </MenuItem>
            </Link>
            <MenuItem
              color='neutral.primary'
              fontWeight='medium'
              onClick={() => {
                setSettingsModalIsOpen(true);
              }}
            >
              {genericTranslation.userMenu.settings}
            </MenuItem>
            <MenuItem
              color='neutral.primary'
              fontWeight='medium'
              onClick={() => {
                signOut();
              }}
            >
              {genericTranslation.userMenu.logOut}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    );
  }

  // logged out, not mobile
  return (
    <HStack gap={6}>
      <Button variant='userSignOutline' onClick={goToSignIn} mr={0}>
        {genericTranslation.signIn.cta}
      </Button>

      <Button variant='userSign' onClick={goToSignUp}>
        {genericTranslation.signUp.cta}
      </Button>
    </HStack>
  );
}

HeaderAccountButtons.propTypes = {
  setSettingsModalIsOpen: PropTypes.func.isRequired,
};
