import { useContext } from 'react';
import { CategoriesContext } from '~/lib/state/contexts/CategoriesContext';

export const useCategories = () => {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategories must be used within CategoriesProvider');
  }

  return context;
};
