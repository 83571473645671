import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { LuLayoutGrid } from 'react-icons/lu';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_SEARCH_ROUTE, QUERY_PARAMS } from '~/lib/constants';

export const CategoryMenu = memo(function CatMenuBase({ categories }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { brandsTranslation: t } = useTranslations();

  if (!Array.isArray(categories)) {
    return null;
  }

  const topCategories = categories.slice(0, 9);
  const restCategories = categories.slice(9);

  const handleClear = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(QUERY_PARAMS.CATEGORY_ID);

    navigate({
      pathname: BRANDS_SEARCH_ROUTE,
      search: newSearchParams.toString(),
    });
  };

  const handleNavigate = (categoryId) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(QUERY_PARAMS.CATEGORY_ID, categoryId);

    navigate({
      pathname: BRANDS_SEARCH_ROUTE,
      search: newSearchParams.toString(),
    }),
      {
        replace: true,
      };
  };

  const activeCategory = categories.find(
    (c) => c.category_id === Number(searchParams.get(QUERY_PARAMS.CATEGORY_ID))
  );

  const renderMenuItem = (c) => (
    <MenuItem
      onClick={() => {
        handleNavigate(c.category_id);
      }}
      value={c.category_id}
      key={c.category_id}
    >
      {c.category}
    </MenuItem>
  );

  return (
    <Menu>
      <MenuButton width='max-content' backgroundColor='transparent' color='header_text'>
        <HStack>
          <Box w='20px' h='20px' color='header_text'>
            <LuLayoutGrid size={20} />
          </Box>
          <Text textStyle='header-text'>
            {activeCategory ? activeCategory.category : t.categories.root}
          </Text>
          <Box w={'16px'} h={'17px'} color='header_text'>
            <IoChevronForward style={{ transform: 'rotate(90deg) translateX(1px)' }} />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList data-testid='global_header_category-menu'>
        {categories ? (
          <>
            <MenuItem onClick={handleClear} value={'all'} key={'all'}>
              {t.categories.all}
            </MenuItem>
            {topCategories.map(renderMenuItem)}
            <MenuDivider />
            <Menu offset={[9, 0]} placement='left-end'>
              <MenuButton pl={3}>{t.categories.more}</MenuButton>
              <MenuList data-testid='global_header_more-menu'>
                {restCategories.map(renderMenuItem)}
              </MenuList>
            </Menu>
          </>
        ) : null}
      </MenuList>
    </Menu>
  );
});

CategoryMenu.propTypes = {
  categories: PropTypes.array.isRequired,
};
