import {
  Box,
  Button,
  CircularProgress,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { PageContainer } from '~/components/page-container';
import { useSearch } from '~/hooks/useSearch';
import { useTranslations } from '~/hooks/useTranslations';
import { IMPRESSION } from '~/lib/constants';
import { useGoogleAnalytics } from '~/lib/state/contexts/GoogleAnalyticsProvider';
import { Brand } from '~/pages/brands/brand';

export function SearchList() {
  const { genericTranslation: t, brandsTranslation: b } = useTranslations();
  const {
    activeCategory,
    brands,
    searchPhrase,
    showMore,
    onMore,
    isBrandsLoading,
    isCategoriesLoading,
  } = useSearch();

  const { logEvent } = useGoogleAnalytics();

  const responsive = useBreakpointValue(
    {
      base: { columns: 1 },
      sm: { columns: 2 },
      md: { columns: 3 },
      lg: { columns: 4 },
      xl: { columns: 4 },
    },
    {
      fallback: 'md',
      ssr: false,
    }
  );

  const searchLabel = useMemo(() => {
    if (searchPhrase?.trim()) {
      return activeCategory
        ? b.searchResultsForIn(searchPhrase, activeCategory.category)
        : b.searchResultsFor(searchPhrase);
    }
    return activeCategory ? b.searchResultsIn(activeCategory.category) : b.searchResults;
  }, [activeCategory, searchPhrase, b]);

  const brandItems = Array.from(brands.values());
  const isLoading = (isBrandsLoading || isCategoriesLoading) && !brandItems.length;
  const noBrandsFound = !isBrandsLoading && !isCategoriesLoading && brands && !brandItems.length;

  useEffect(() => {
    if (logEvent) {
      logEvent(IMPRESSION, {
        page: 'search_page',
        category_id: activeCategory?.category_uid ?? null,
        category_name: activeCategory?.category ?? null,
        search_phrase: searchPhrase,
        advertiser_uids: brandItems.map((brand) => brand.brand_uid),
        advertiser_offer_uids: brandItems.flatMap((brand) =>
          brand.offers.map((offer) => offer.offer_uid)
        ),
      });
    }
  }, [activeCategory, brandItems, logEvent, searchPhrase]);

  return (
    <PageContainer>
      <Heading
        sx={{
          alignSelf: 'flex-start',
          fontSize: '1.75rem',
          color: 'neutral.primary',
          textAlign: 'start',
          marginBottom: 7,
        }}
      >
        {searchLabel}
      </Heading>
      {isLoading ? (
        <Box>
          <CircularProgress size={8} isIndeterminate color='brand.primary' />
        </Box>
      ) : (
        <SimpleGrid columns={responsive.columns} spacing={5} width='100%'>
          {brandItems.map((brand) => (
            <Brand key={brand.brand_uid} brand={brand} />
          ))}
        </SimpleGrid>
      )}
      {showMore && !isLoading && (
        <Button isDisabled={isLoading} onClick={onMore} alignSelf='center' marginTop={16}>
          {isBrandsLoading ? <Spinner color='white' /> : t.loadMore}
        </Button>
      )}
      {noBrandsFound && (
        <Box>
          <Text>{b.noBrands}</Text>
        </Box>
      )}
    </PageContainer>
  );
}
