import {
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { handleError } from '~/lib/errors';
import { settingsModalView } from './settings-modal-container';

export const ZipCodeFormModal = ({ setView }) => {
  const toast = useToast();
  const { genericTranslation } = useTranslations();
  const { zipCodeValidation } = useValidationSchema();
  const { userZipCode } = useAccountState();
  const { updateZipCode } = useAccountAction();
  const translations = genericTranslation.settings.zipCode;

  const validationSchema = Yup.object().shape({
    zipCode: zipCodeValidation({ required: true }),
  });

  const initialValues = {
    zipCode: userZipCode,
  };

  const submitForm = async (values) => {
    try {
      await updateZipCode(values.zipCode);
      return setView(settingsModalView.zipCode.success);
    } catch (error) {
      handleError(toast, error, translations.errorMessage);
    }
  };

  const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  return (
    <>
      <ModalHeader display='flex' alignItems='center' flexDirection='column' pt={0}>
        <Text
          as='h1'
          fontWeight='medium'
          fontSize='lg'
          fontFamily='display'
          color='neutral.primary'
        >
          {translations.header}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody pt={0} display='flex' flexDirection='column' alignItems='flex-start' gap={4}>
        <VStack as='form' width='100%' spacing={2} onSubmit={handleSubmit}>
          <FormControl>
            <InputGroup>
              <Input
                name='zipCode'
                layerStyle='sign-in-field'
                type={'text'}
                value={values.zipCode}
                placeholder={translations.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </FormControl>
          <ErrorRow isPresent={Boolean(errors.zipCode)}>{errors.zipCode}</ErrorRow>
          <HStack width='100%'>
            <Button
              variant='outline'
              colorScheme='secondary_scheme'
              isDisabled={isSubmitting}
              onClick={() => {
                setView(settingsModalView.root);
              }}
              flexBasis='50%'
            >
              {genericTranslation.back}
            </Button>
            <Button isDisabled={isSubmitting} type='submit' flexBasis='50%'>
              {translations.update}
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </>
  );
};

ZipCodeFormModal.propTypes = {
  setView: PropTypes.func.isRequired,
};
