import { LDProvider } from 'launchdarkly-react-client-sdk';
import * as PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Chakra } from '~/components/Chakra';
import { AccountProvider } from '~/lib/state/contexts/AccountContext/AccountContext';
import AlertProvider from '~/lib/state/contexts/AlertContext';
import { AwsWafProvider } from '~/lib/state/contexts/AwsWafContext';
import { CategoriesProvider } from '~/lib/state/contexts/CategoriesContext';
import FinanceProvider from '~/lib/state/contexts/FinanceContext';
import { GoogleAnalyticsProvider } from '~/lib/state/contexts/GoogleAnalyticsProvider';
import { ModalProvider } from '~/lib/state/contexts/ModalContext';
import { UkFinanceProvider } from '~/lib/state/contexts/UkFinanceContext';

const launchDarklyClientId = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

export const Provider = ({ children }) => (
  <LDProvider
    clientSideID={launchDarklyClientId}
    options={{
      streaming: true,
      logger: {
        debug: () => {},
        info: () => {},
        log: () => {},
        error: console.error,
        warn: console.warn,
      },
    }}
  >
    <Chakra>
      <AwsWafProvider>
        <HelmetProvider>
          <AlertProvider>
            <AccountProvider>
              <GoogleAnalyticsProvider>
                <FinanceProvider>
                  <UkFinanceProvider>
                    <ModalProvider>
                      <CategoriesProvider>{children}</CategoriesProvider>
                    </ModalProvider>
                  </UkFinanceProvider>
                </FinanceProvider>
              </GoogleAnalyticsProvider>
            </AccountProvider>
          </AlertProvider>
        </HelmetProvider>
      </AwsWafProvider>
    </Chakra>
  </LDProvider>
);

Provider.propTypes = {
  children: PropTypes.node,
};
