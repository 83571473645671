import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

const FLAG_QUERY_PARAM = 'ff';
const FLAG_SEPARATOR = ';';
const VALUE_SEPARATOR = ':';
const FLAG_CLEAR = 'clear';
const FLAG_STORAGE_KEY = 'ff';

const parseStringToBoolean = (value) => {
  if (value === true) return true;

  switch (value.toLowerCase()) {
    case 'true':
    case '1':
      return true;
    default:
      return false;
  }
};

const mergeWithQueryParams = (ldFlags, userOverrides) => {
  const newFlags = { ...ldFlags };

  userOverrides?.split(FLAG_SEPARATOR).forEach((params) => {
    const [paramKey, paramValue] = params.split(VALUE_SEPARATOR);
    const formattedKey = camelCase(paramKey);

    if (formattedKey in ldFlags) {
      newFlags[formattedKey] = parseStringToBoolean(paramValue);
    }
  });

  return newFlags;
};

/**
 * A hook for reading one or all registered feature flags.
 *
 * @param {string} [flagKey] - An optional key representing the flag whose status you want to read.
 * @returns {Object<string, boolean> | { string: boolean }} -
 * Either an object with string keys and values containing an `enabled` boolean,
 * or a single object with an `enabled` boolean.
 */
export const useFeatureFlag = (flagKey) => {
  let [searchParams] = useSearchParams();
  const userOverrides =
    searchParams.get(FLAG_QUERY_PARAM) || sessionStorage.getItem(FLAG_STORAGE_KEY);
  const ldFlags = useFlags();

  let flagsWithOverrides;
  if (!userOverrides || userOverrides === FLAG_CLEAR) {
    if (userOverrides === FLAG_CLEAR) {
      sessionStorage.removeItem(FLAG_STORAGE_KEY);
    }

    flagsWithOverrides = { ...ldFlags };
  } else {
    sessionStorage.setItem(FLAG_STORAGE_KEY, userOverrides);
    flagsWithOverrides = mergeWithQueryParams(ldFlags, userOverrides);
  }

  return flagKey ? flagsWithOverrides[flagKey] : flagsWithOverrides;
};
