import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { CURRENT_TOC_VERSION_NUM, PREFS_TERMS, TOC_API_URL } from '~/lib/constants';
import { BadStatusError, DEFAULT_TITLE, handleError, isBadStatusError } from '~/lib/errors';
import { neutralizeEvent } from '~/lib/helpers';
import { useAccountAction } from './account/useAccountAction';
import { useAccountState } from './account/useAccountState';

export const useTerms = () => {
  const fetchUrl = useFetchUrl();
  const toast = useToast();
  const { isLoggedIn } = useAccountState();
  const { signOut } = useAccountAction();

  const checkNeedsTermAcceptanceRemote = useCallback(async () => {
    try {
      const result = await fetchUrl(TOC_API_URL + 'accepted');

      const data = await result.json();

      const hasAccepted = typeof data?.revision === 'number';

      if (hasAccepted) {
        localStorage.setItem(PREFS_TERMS, data?.revision);
      }

      return !hasAccepted;
    } catch (err) {
      if (isLoggedIn && err instanceof BadStatusError && err.response.status === 404) {
        // server can not find record of user's terms acceptance, so we assume they need to accept
        return true;
      } else {
        // some unexpected error occurred
        handleError(toast, err, DEFAULT_TITLE);
      }
    }
  }, [fetchUrl, isLoggedIn, toast]);

  const checkNeedsTermAcceptance = useCallback(async () => {
    const localPrefsTerms = localStorage && localStorage.getItem(PREFS_TERMS);
    if (localStorage && localStorage.getItem('accessToken') && localPrefsTerms !== null) {
      // return false (does not need to accept T&Cs if any revision number is present)
      const onlyNumericChars = /^\d+$/;
      const hasAccepted = onlyNumericChars.test(localPrefsTerms);
      return !hasAccepted;
    }

    // could not find record of most recent terms acceptance in localStorage; querying API now instead
    return await checkNeedsTermAcceptanceRemote();
  }, [checkNeedsTermAcceptanceRemote]);

  const acceptTerms = useCallback(
    async (e) => {
      try {
        neutralizeEvent(e);

        const response = await fetchUrl(TOC_API_URL + CURRENT_TOC_VERSION_NUM, { method: 'POST' });
        const body = await response.json();
        localStorage.setItem(PREFS_TERMS, body.revision);
        return true;
      } catch (err) {
        handleError(toast, err, DEFAULT_TITLE);

        if (isBadStatusError(err) && err.response?.status === 401) {
          signOut();
        }
      }
    },
    [signOut, fetchUrl, toast]
  );

  return {
    acceptTerms,
    checkNeedsTermAcceptance,
  };
};
