export const financeTranslation = {
  linkedInstitutionsErrorTitle: 'Bad data for linked institutions',
  linkedInstitutionsErrorDescription: 'Cannot find linked institutions',
  linkingCardErrorTitle: 'Error linking card',
  sessionExpiredTitle: 'Session expired',
  sessionExpiredDescription: 'Please log in again',
  cardLinked: 'Card linked',
  cardUnlinked: 'Card unlinked',
  cardLinkedFromX: (institutionName) => `Linked card from ${institutionName}`,
  cardNotLinked: 'Card NOT linked',
  errorFetchingCards: 'Error fetching cards',
  cannotLink: 'Cannot link',
  cannotUnlink: 'Cannot unlink card',
  tokenError: 'Token error',
  unlinkCardMessage: (institutionName) =>
    institutionName
      ? `Your card for ${institutionName} is no longer being tracked by our rewards service`
      : 'Your card is no longer being tracked by our rewards service',
  noCards: 'Don’t miss out! Link an account to start earning cash!',
  myWallet: 'My Wallet',
  linkACard: 'Link a card',
  linkMoreCards: 'Link more cards',
  linkedCards: 'Linked Cards',
  addACard: 'Add a card',
  addCard: 'Add Card',
  bankAccounts: 'Bank Accounts',
  disconnected: 'Disconnected',
  reauthorizationFailedTitle: 'Reauthorization failed',
  noPlaidLinkToken: 'Did not receive a valid link token from Plaid',
  rewardsRegistrationModal: {
    intro: {
      header: 'Unlock 5% Cashback at over 5,000 Restaurants',
      body: [`Earn 5% cashback automatically when you dine in and pay with your linked card`],
      cta: 'Get Started',
    },
    zipCode: {
      header: 'Where do you typically dine?',
      placeholder: 'Enter your zip code',
      body: [`Share your zip code so we can find the restaurants you visit most often.`],
      cta: 'Continue',
    },
    confirmCards: {
      header: 'Confirm Your Card Details',
      body: ['To earn cashback, confirm the last four digits of the card you use when dining in.'],
      placeholder: 'Enter last 4 digits of card',
      cardReadOnly: (value) => `Card ending in ${value}`,
      cta: 'Confirm Card',
      secondaryCta: 'Manage My Accounts',
      error: 'Unable to confirm card details. Please try again.',
    },
    outro: {
      header: "You're all set!",
      body: [
        'Dine in at any of our 5,000 restaurants and pay with your linked card to earn 5% cashback automatically.',
      ],
      cta: 'Start Dining',
    },
    exit: {
      header: 'Are you sure you want to leave without saving?',
      primaryCta: 'Stay',
      secondaryCta: 'Leave without saving',
    },
  },
};
